.legal-container {
  padding: 150px 45px 50px;
  font-family: 'Lexend', sans-serif;
  color: #11111f;
  font-weight: normal;
  max-width: 1000px;
}

.legal-heading {
  margin-bottom: 32px;
}

.legal-first-paragraph {
  text-indent: 0 !important;
}

.legal-container section {
  margin-bottom: 20px;
}

.legal-container p {
  font-size: 16px;
  text-indent: 5%;
  padding: 10px 0 10px;
}

.legal-container h1 {
  font-size: 32px;
  margin-bottom: 0px;
}

.legal-container h2 {
  font-size: 24px;
}

.topics-group {
  padding: 15px 0 15px 35px;
}

p.topic {
  padding: 0;
}